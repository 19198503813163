<mat-list-item [routerLink]="link">
  <img
    matListItemAvatar
    [src]="
      data.image && status !== false
        ? config.storageUrl + data.image
        : 'assets/img/account.svg'
    "
    alt="..."
  />
  <div class="name-statuses">
    <div class="name-lastmessage">
      <div class="name">
        {{ name ? name : ("CHAT.NO_NAME" | translate) }}
      </div>
      <div
        class="last-message"
        *ngIf="chatData && chatData.lastMessageText"
        [class.typing]="chatId && auth.typing[chatId]"
      >
        <div *ngIf="chatId && !auth.typing[chatId]">
          {{ lastMessageText }}
        </div>
        <div *ngIf="chatId && auth.typing[chatId]">Typing..</div>
      </div>
    </div>
    <div class="statuses">
      <!-- isWebchat as icon at the end -->
      <mat-icon
        *ngIf="chatData && chatData.isWebchat"
        matTooltip="Webchat"
        class="appointment"
        matListItemIcon
        >public</mat-icon
      >
      <!-- isUnwantedBehavior as icon at the end -->
      <mat-icon
        *ngIf="chatData && chatData.isUnwantedBehavior"
        matTooltip="Unerwünschtes Verhalten"
        class="appointment"
        matListItemIcon
        >warning</mat-icon
      >
      <!-- unreadMessages as badge -->
      <div
        *ngIf="chatData && chatData.unreadMessages && showUnread"
        class="unread"
        matListItemMeta
      >
        <div>{{ chatData.unreadMessages }}</div>
      </div>
    </div>
  </div>
</mat-list-item>
