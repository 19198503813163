import { Injectable, Inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { ConfirmDialogComponent } from "../../shared/confirm-dialog/confirm-dialog.component";

import { of } from "rxjs";
import { Socket } from "ngx-socket-io";
import { Router } from "@angular/router";
import { NotificationService } from "./notification.service";
import { MatDialog } from "@angular/material/dialog";
import { AppConfigService } from "../../app-config.service";
import { environment } from "../../../environments/environment";
import { StorageService } from "../../storage.service";
import { EncryptionService } from "../../encryption.service";
import { TranslateService } from "@ngx-translate/core";

@Injectable()
export class SocketOne extends Socket {
  constructor(@Inject("_OPTIONS_") public options) {
    super({
      url: environment.socketUrl,
      options,
    });
  }
}

type IChats = Record<
  string,
  {
    appUserName: string;
    id: number;
    encryptedName: string;
    chatData: {
      created_at: number;
      isWebchat: boolean;
      isUnwantedBehavior: boolean;
      unreadMessages: number;
      lastMessageDate: string;
      lastMessageText: string;
    };
  }
>;

@Injectable({
  providedIn: "root",
})
export class AuthenticationService {
  showNotificationNotice: any = false;
  awayMessage: any = false;
  io;
  env = environment;

  myCallId = false;
  incomingCall = false;
  calling = false;

  archive: any = null;
  insightsCompanies = {};
  insightsCompanyDepartments: any = {};
  insightsArticles = [];
  insightsGraphs = {};
  insightsArticlesSorted = [];
  insightsScreens = [];
  insightsUserData = {};
  insightsWordcloud: any = false;
  insightsScreensSorted = [];
  insightsCodes = [];
  insightsCodesSorted = [];
  conversations: Record<
    string,
    Record<
      string,
      {
        appUserName: string;
        id: number;
        encryptedName: string;
        chatData: {
          created_at: number;
          isWebchat: boolean;
          isUnwantedBehavior: boolean;
          unreadMessages: number;
          lastMessageDate: string;
          lastMessageText: string;
        };
      }
    >
  > = { unassigned: {}, pending: {}, myChats: {} };
  currentChat: any = {};
  currentChatId: any = false;
  connected = false;
  online = {} as Record<string, any>;
  scrollContainer;
  setup2faImage = "";
  setup2fa = false;
  status = "available";
  typingTimeout: any = {};
  typing: any = {};
  dashboardType = "";
  myPin: any = false;

  myConversationsRaw = {};
  conversationsRaw = {};

  constructor(
    private http: HttpClient,
    private config: AppConfigService,
    private router: Router,
    private notificationService: NotificationService,
    @Inject("LOCALSTORAGE") private localStorage: Storage,
    private encryptionService: EncryptionService,
    private storage: StorageService,
    private translate: TranslateService,
    public dialog: MatDialog
  ) {
    if (
      window.location.hostname.includes("insights") ||
      window.location.hostname.includes("analytics")
    ) {
      this.dashboardType = "insights";
    } else if (
      window.location.hostname.includes("webchat") ||
      window.location.hostname.includes("corrente")
    ) {
      this.dashboardType = "webchat";
    } else if (window.location.hostname === "chat") {
      this.dashboardType = "chat";
    } else {
      this.dashboardType = environment.fallback;
    }

    if (window.location.hash.includes("consultant")) {
      this.dashboardType = "chat";
    }

    this.storage.dashboardType = this.dashboardType;

    this.encryptionService.pgpStorageKey = "pgpData-" + this.dashboardType;

    this.io = new SocketOne({
      path: environment.socketPath,
      query: "origin=" + this.dashboardType,
      reconnection: true,
      timeout: 5000,
      transports: ["xhr-polling", "websocket", "polling"],
      reconnectionDelay: 5000,
    });
    if (this.dashboardType === "webchat") {
      this.io.on("reconnect", (data: any) => {
        if (this.getCurrentUser() && this.getCurrentUser().loginToken) {
          this.io.emit("verifyToken", this.getCurrentUser().loginToken);
        }
      });
      this.io.on("disconnect", () => {
        this.notificationService.openSnackBar(
          "You are disconnected, trying to reconnect! One moment please.."
        );
        this.connected = false;
      });
      this.io.on("webchat-error", (error) => {
        this.notificationService.openSnackBar(error.message);
        if (error.action && error.action === "logout") {
          this.logout();
          this.router.navigate(["/auth/webchat-login"]);
        }
      });
      this.io.on("video-call", (data) => {
        console.log("Incoming call", data);
        this.incomingCall = data;
      });
      this.io.on("publicKey", (data) => {
        this.encryptionService.serverPublicKey = data;
      });
    } else if (this.dashboardType === "chat") {
      this.io.on("archived", () => {
        this.router.navigate(["/"]);
      });
      this.io.on("archive-data", async (data) => {
        this.processArchive(data);
      });
      this.io.on("retry-get-chat", (data) => {
        this.io.emit("get-chat", data);
      });
    } else if (this.dashboardType === "insights") {
      this.io.on("companies", (data) => {
        this.insightsCompanies = data;
      });
      this.io.on("departments", (data) => {
        this.insightsCompanyDepartments = data;
      });
      this.io.on("insights-articles", (data) => {
        this.insightsArticles = data;
        this.insightsArticlesSorted = data;
      });
      this.io.on("insights-screens", (data) => {
        this.insightsScreens = data;
        this.insightsScreensSorted = data;
      });
      this.io.on("insights-wordcloud", (data) => {
        console.log(typeof data);
        this.insightsWordcloud = Object.assign({}, data);
      });
      this.io.on("insights-user-data", (data) => {
        // data.age = {unknown: 13, 26-39: 6, 0-25: 2, 40-49: 6};
        // sort age but keep unknown at the begin
        const age = {};
        Object.keys(data.age)
          .sort()
          .forEach(function (key) {
            age[key] = data.age[key];
          });
        this.insightsUserData["age"] = [
          {
            name: "Age",
            x: Object.keys(age),
            y: Object.values(age),
            type: "bar",
          },
        ];
        this.insightsUserData["gender"] = [
          {
            name: "Gender",
            labels: Object.keys(data.gender),
            values: Object.values(data.gender),
            type: "pie",
          },
        ];
        this.insightsUserData["language"] = [
          {
            name: "Language",
            labels: Object.keys(data.languages),
            values: Object.values(data.languages),
            type: "pie",
          },
        ];
      });
      this.io.on("insights-graphs", (data) => {
        this.insightsGraphs["logins"] = {};
        this.insightsGraphs["screens"] = {};
        this.insightsGraphs["logins"] = [
          {
            name: "Logins",
            x: Object.keys(data.logins).map((val) =>
              val.length > 7 ? val : "- " + val + " "
            ),
            y: Object.values(data.logins),
            type: "bar",
            orientation: "v",
          },
        ];
        this.insightsGraphs["screens"] = [
          {
            name: "Screen Views",
            x: Object.keys(data.screenViews).map((val) =>
              val.length > 7 ? val : "- " + val + " "
            ),
            y: Object.values(data.screenViews),
            type: "line",
            orientation: "v",
          },
        ];
      });
      this.io.on("insights-codes", (data) => {
        this.insightsCodes = data;
        this.insightsCodesSorted = data;
      });
    }
    this.io.on("pgp-data", async (data) => {
      if (data) {
        this.storage.setStorageObject(
          this.encryptionService.pgpStorageKey,
          data
        );
        await this.encryptionService.setKeys();
      }
      if (!this.encryptionService.keysSet && this.dashboardType === "chat") {
        if (!this.requesting) {
          this.requesting = true;
          this.request();
        }
      }
    });
    this.io.on("login-success", async (data) => {
      if (!data.msg) {
        // const pgpData = this.storage.getStorageObject(
        //   this.encryptionService.pgpStorageKey,
        //   false
        // );
        if (this.myPin) {
          await this.encryptionService.setKeys(this.myPin);
        } else {
          this.io.emit("request-pgp", "HNCXSzYs8f5KeYJcBBR5LDh8PE7dLkyw");
        }
        const pushToken = this.storage.getStorageObject("push-token");
        if (!pushToken) {
          this.showNotificationNotice = true;
        } else {
          this.io.emit("push-token", pushToken);
        }
        this.localStorage.setItem("currentUser", JSON.stringify(data));
        if (this.currentChatId) {
          this.io.emit("get-chat", this.currentChatId);
          this.notificationService.openSnackBar("Reconnected..", 1000);
        }
        this.connected = true;
      }
    });
    this.io.on("reconnect", (data) => {
      if (this.getCurrentUser() && this.getCurrentUser().loginToken) {
        this.io.emit("verifyToken", this.getCurrentUser().loginToken);
      }
    });
    this.io.on("disconnect", () => {
      this.notificationService.openSnackBar(
        "You are disconnected, trying to reconnect! One moment please.."
      );
      this.connected = false;
    });
    this.io.on("download", (data) => {
      const blob = new Blob([data], { type: "application/pdf" });
      const url = this.config.socketUrl + data;
      const pwa = window.open(url);
      if (!pwa || pwa.closed || typeof pwa.closed === "undefined") {
        alert("Please disable your Pop-up blocker and try again.");
      }
    });
    this.io.on("user-typing", (data) => {
      const chatId = data.chatId;
      this.typing[chatId] = data.typing;
      setTimeout(() => {
        this.scrollDown();
      }, 1);
      if (this.typingTimeout[chatId]) {
        clearTimeout(this.typingTimeout[chatId]);
      }
      this.typingTimeout[chatId] = setTimeout(() => {
        this.typing[chatId] = false;
        setTimeout(() => {
          this.scrollDown();
        });
      }, 5000);
    });
    this.io.on("setup-2fa", (data) => {
      this.setup2fa = true;
      this.setup2faImage = data.data_url;
    });
    this.io.on("login-error", (data) => {
      if (data?.translation) {
        this.notificationService.openSnackBar(
          this.translate.instant(data.translation),
          5000
        );
      } else if (data?.msg) {
        this.notificationService.openSnackBar(
          this.translate.instant(data.msg),
          5000
        );
      }
      // this.localStorage.setItem("currentUser", "{}");
      if (data.goto) {
        this.router.navigate([data.goto]);
      } else {
        this.router.navigate(["/auth/login"]);
      }
    });

    this.io.on("my-conversations", (data) => {
      this.myConversationsRaw = data;
      this.processMessages(data);
    });
    this.io.on("conversations", (data) => {
      this.conversationsRaw = data;
      this.processMessages(data);
    });
    this.io.on("online", (data) => {
      if (JSON.stringify(this.online) !== JSON.stringify(data)) {
        this.online = data;
      }
    });
    this.io.on("not-loggedin", (data) => {
      this.connected = false;
      if (this.getCurrentUser() && this.getCurrentUser().loginToken) {
        this.io.emit("verifyToken", this.getCurrentUser().loginToken);
      }
    });
    this.io.on("chat-data", async (data) => {
      this.currentChat = data;
      console.log(data);
      this.connected = true;
      if (this.currentChat?.info?.encryptedName) {
        this.currentChat.info.appUserName =
          await this.encryptionService.decryptMessage(
            this.currentChat.info.encryptedName
          );
      }

      setTimeout(() => {
        this.scrollDown();
      });
    });
    this.io.on("chat-info", (data) => {
      this.currentChat["info"] = data;
    });

    this.io.on("away-message-data", (data) => {
      this.awayMessage = data;
    });

    this.io.on("show-snackbar", (data) => {
      this.notificationService.openSnackBar(data, 2000);
    });

    this.io.on("messages", (data) => {
      this.currentChat["messages"] = data;
      this.scrollDown();
    });
    this.io.on("message", (data) => {
      if (this.currentChatId) {
        this.typing[this.currentChatId] = false;
      }
      if (!this.currentChat["messages"]) {
        this.currentChat["messages"] = [];
      }
      this.currentChat["messages"].push(data);
      this.scrollDown();
    });
    if (this.getCurrentUser() && this.getCurrentUser().loginToken) {
      this.io.emit("verifyToken", this.getCurrentUser().loginToken);
    }
  }

  requesting = false;
  request() {
    this.requesting = true;
    setTimeout(() => {
      this.requesting = false;
    }, 6000);
  }

  async processArchive(data) {
    for (const key in data) {
      if (data[key]?.encryptedName) {
        data[key].appUserName = await this.encryptionService.decryptMessage(
          data[key]?.encryptedName ?? data[key].appUserName
        );
      }
    }
    this.archive = data;
  }

  async processMessages(data: {
    unassigned: IChats;
    pending: IChats;
    myChats: IChats;
  }) {
    if (!this.encryptionService.keysSet) {
      setTimeout(async () => {
        this.processMessages(data);
      }, 500);
    }
    for (const key in data) {
      if (data[key]) {
        for (const i in data[key]) {
          if (this.encryptionService.keysSet && data[key][i]?.encryptedName) {
            data[key][i].appUserName =
              await this.encryptionService.decryptMessage(
                data[key][i]?.encryptedName ?? data[key][i].appUserName
              );
          } else if (data[key][i].encryptedName) {
            data[key][i].appUserName = "Encrypted Name";
          }
          if (
            data[key]?.[i] &&
            JSON.stringify(this.conversations[key]?.[i] ?? "{}") !==
              JSON.stringify(data[key][i])
          ) {
            if (!this.conversations[key]) {
              console.log("Creating new key", key);
              this.conversations[key] = {};
            }
            this.conversations[key][i] = data[key][i];
          }
        }
        for (const i in this.conversations[key]) {
          if (!data[key][i]) {
            delete this.conversations[key][i];
          }
        }
      }
    }
  }

  toggleStatus(newStatus) {
    this.status = newStatus.value;
    if (this.status === "unavailable") {
      if (Object.keys(this.conversations["myChats"]).length === 0) {
        this.io.emit("status", { value: this.status });
      } else {
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
          width: "350px",
          data: {
            title: this.translate.instant("CHAT.MODAL_CHANGE_STATUS.TITLE"),
            message: this.translate.instant("CHAT.MODAL_CHANGE_STATUS.TEXT"),
          },
        });
        dialogRef.afterClosed().subscribe((result) => {
          if (result) {
            this.io.emit("status", { value: this.status });
          } else {
            this.status = "available";
          }
        });
      }
    } else {
      this.io.emit("status", { value: this.status });
    }
  }

  login(email: string, password: string, twoFa: string, passPhrase: string) {
    this.encryptionService.setEncryptionPassphrase(passPhrase);
    this.io.emit("login", { email: email, password: password, twoFa: twoFa });
  }

  async loginWebchat(username: string, pin: string) {
    await this.encryptionService.generateWebKeys(pin);
    const pgpData: any = this.storage.getStorageObject(
      this.encryptionService.pgpStorageKey,
      false
    );
    this.io.emit("webchat-login", {
      username: username,
      pin: pin,
      publicKey: pgpData.publicKey ?? false,
    });
  }

  logout(): void {
    this.myPin = false;
    // clear token remove user from local storage to log user out
    this.localStorage.removeItem("currentUser");
  }

  getCurrentUser(): any {
    return JSON.parse(this.localStorage.getItem("currentUser"));
  }

  passwordResetRequest(email: string) {
    return of(true);
  }

  changePassword(currentPwd: string, newPwd: string) {
    if (this.getCurrentUser()) {
      this.io.emit("change-password", {
        currentPassword: currentPwd,
        newPassword: newPwd,
      });
    }
  }

  passwordReset(
    email: string,
    token: string,
    password: string,
    confirmPassword: string
  ): any {
    return of(true);
  }

  scrollDown() {
    setTimeout(() => {
      const scrollContainer =
        this.scrollContainer ?? document.getElementById("chat-messages-scroll");
      if (scrollContainer) {
        scrollContainer.scroll({
          top: scrollContainer.scrollHeight,
          left: 0,
          behavior: "smooth",
        });
        this.scrollContainer = scrollContainer;
      }
    }, 25);
  }
}
